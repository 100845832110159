.task-list {
  padding: 25px 20px 10px 20px;
  -webkit-box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  background: #fff;
  border-radius: 4px;
  transition: 0.2s ease-in-out;
}

.status {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
}

.circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 35px;
  margin-right: 10px;
}

.circle-green {
  background: #3ad787;
  animation: pulseGreen 2s infinite;
}

.circle-red {
  background: #ee5253;
  animation: pulseRed 2s infinite;
}

@keyframes pulseGreen {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(58, 215, 135, 0.7);
  }

  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 5px rgba(58, 215, 135, 0);
  }

  100% {
    transform: scale(0.9);
  }
}

@keyframes pulseRed {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(238, 82, 82, 0.7);
  }

  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 5px rgba(238, 82, 82, 0);
  }

  100% {
    transform: scale(0.9);
  }
}

label:hover {
  cursor: default;
}

.checkbox {
  position: relative;
  padding: 0px 20px;
  word-break: break-all;
}

.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background: #fff;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked,
.checkbox-round:focus {
  outline: none !important;
}

.checkbox-round:checked {
  border: 1px solid #ddd;
  background: #ddd;
}

.checkbox:hover .remove-btn {
  display: block;
  opacity: 0.3;
}

.remove-btn {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 25px;
  height: 40px;
  margin: auto 0;
  font-size: 30px;
  color: #333;
  margin-bottom: 11px;
  background: none;
  border: 0px;
  outline: none;
}

.remove-btn:after {
  content: '×';
}

.remove-btn:hover {
  opacity: 1 !important;
}

input[type='checkbox']:checked + label {
  color: #ddd;
  text-decoration: line-through;
}
