body {
  background: #f6f9fc;
  color: #223d61;
  margin: 30px 0px;
}

hr {
  border-top: 1px solid #ebf1f8;
  margin-left: -20px;
  margin-right: -20px;
}

label {
  font-size: 20px;
  font-weight: normal;
  padding-left: 10px !important;
  margin-bottom: 10px !important;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.btn {
  padding: 20px;
  font-weight: bold;
  cursor: pointer;
  border: 0px;
  border-radius: 4px;
}

.btn-green {
  color: #fff;
  background: linear-gradient(to bottom, #3ad787, #2ace7a);
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 30%), 0 2px 15px rgb(58 215 135 / 30%);
}

.btn-green:hover,
.btn-green:focus {
  color: #fff;
  background: linear-gradient(to bottom, #4fdb94, #32d582);
  outline: none;
}

.btn-task-bar {
  border-radius: 0 4px 4px 0;
}

.btn-clear-tasks {
  display: block;
  margin: 20px auto 0 auto;
  min-width: 200px;
  font-size: 16px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-15px);
}

.btn-clear-active {
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease-in-out;
  transform: translateY(0);
}

.animate {
  animation-fill-mode: both;
  animation-delay: 0.3s;
  animation-duration: 0.3s !important;
  transition-duration: 0.3s !important;
}

.animate-fade-down {
  animation: fadeDown;
}

@keyframes fadeDown {
  from {
    transform: translateY(-15px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
