.task-bar {
  display: flex;
  margin: 20px 0px;
}

.task-bar input {
  width: 100%;
  height: 60px;
  border: 0px;
  outline: none;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
  padding: 20px 20px 18px;
  font-size: 20px;
  -webkit-transition: all ease 300ms;
  transition: all ease 300ms;
}

.task-bar input:focus {
  -webkit-box-shadow: 0 2px 17px rgba(0, 0, 0, 0.125);
  box-shadow: 0 2px 17px rgba(0, 0, 0, 0.125);
}

.task-bar input::-webkit-input-placeholder, /* Chrome/Opera/Safari */
.task-bar input::-moz-placeholder, /* Firefox 19+ */
.task-bar input:-moz-placeholder, /* Firefox 18- */
.task-bar input:-ms-input-placeholder /* IE 10+ */ {
  color: #ccc;
}
