.header-date {
  text-align: center;
}

.header-date h1 {
  font-size: 50px;
  margin-bottom: 10px;
}

.header-date h2 {
  font-size: 18px;
  color: #5c66d5;
  margin-top: 0;
  margin-bottom: 45px;
}
